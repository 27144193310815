/*===================================
=                                   =
=  - !Hello There                   =
=  - this is Your working space     =
=  - here you can customize phenix  =
=  - to your app ui design needs    =
=  - Good Luck :)                   =
=                                   =
===================================*/

/*====> Import Phenix <====*/
import Phenix from '.';

//======> D.O.M is Ready [for you] <=======//
Phenix(document).ready((event:any) => {
    //===> Sticky Elements <===//
    Phenix("[data-sticky]").sticky();

    //===> Responsive Menu <===//
    Phenix(".px-navigation").menu();

    //===> Sliders <===//
    Phenix(".px-slider").slider();

    //===> Multimedia <===//
    Phenix(".px-media").multimedia();

    //===> Animations <===//
    Phenix(".px-animate").animations({
        animateCSS: ["fading", "sliding", "attentions", "zooming", "utilities"],
    });

    //===> Custom Scrollbar <===//
    Phenix("html").addClass("px-scrollbar");
}).utilities();
